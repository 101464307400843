var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - Admin: Notifications" }
      }),
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Notifications")]),
      _vm.loading
        ? _c("ck-loader")
        : [
            _c("ck-notifications-table", {
              attrs: { notifications: _vm.notifications }
            }),
            _c(
              "gov-body",
              [
                _vm._v(
                  "\n      Page " +
                    _vm._s(_vm.currentPage) +
                    " of " +
                    _vm._s(_vm.lastPage) +
                    "\n      "
                ),
                _vm.currentPage > 1
                  ? _c("gov-link", { on: { click: _vm.onPrevious } }, [
                      _vm._v("Back")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentPage < _vm.lastPage
                  ? _c("gov-link", { on: { click: _vm.onNext } }, [
                      _vm._v("Next")
                    ])
                  : _vm._e()
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }