var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-table",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "col" } }, [
                _vm._v("Status")
              ]),
              _c("gov-table-header", { attrs: { scope: "col" } }, [
                _vm._v("Channel")
              ]),
              _c("gov-table-header", { attrs: { scope: "col" } }, [
                _vm._v("Type")
              ]),
              _c("gov-table-header", { attrs: { scope: "col" } }, [
                _vm._v("Recipient")
              ]),
              _c("gov-table-header", { attrs: { scope: "col" } }, [
                _vm._v("Date / Time")
              ]),
              _c("gov-table-header", { attrs: { scope: "col", right: "" } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _vm._l(_vm.notifications, function(notification) {
            return _c(
              "gov-table-row",
              { key: notification.id },
              [
                _c(
                  "gov-table-cell",
                  [
                    _c(
                      "gov-tag",
                      {
                        class:
                          "notification-status notification-status--" +
                          _vm.formatStatus(notification)
                      },
                      [_vm._v(_vm._s(_vm.formatStatus(notification)))]
                    )
                  ],
                  1
                ),
                _c("gov-table-cell", [
                  _vm._v(_vm._s(_vm.formatChannel(notification.channel)))
                ]),
                _c("gov-table-cell", [
                  _vm._v(_vm._s(_vm.formatType(notification.notifiable_type)))
                ]),
                _c("gov-table-cell", [_vm._v(_vm._s(notification.recipient))]),
                _c("gov-table-cell", [
                  _vm._v(_vm._s(_vm.formatSentAt(notification)))
                ]),
                _c(
                  "gov-table-cell",
                  { attrs: { right: "" } },
                  [
                    _c(
                      "gov-link",
                      {
                        attrs: {
                          to: {
                            name: "notifications-show",
                            params: { notification: notification.id }
                          }
                        }
                      },
                      [_vm._v("View")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm.notifications.length === 0
            ? _c(
                "gov-table-row",
                [
                  _c(
                    "gov-table-cell",
                    { attrs: { center: "", colspan: "6" } },
                    [_vm._v("No notifications")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }